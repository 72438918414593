<template>
  <v-row>
    <v-col
      v-if="curPathName === 'Investigation'"
      xl="6"
      lg="6"
      md="12"
      sm="12"
      xs="12"
      cols="12"
    >
      <InvestigationCard
        :redirect-path="'/investigation/transaction/createCase/TransactionCase'"
        asset-image="InvTransaction.jpg"
        title="$l.paths.transactionInvestigation"
      />
    </v-col>

    <v-col
      v-if="curPathName === 'InvestigationPerson'"
      xl="6"
      lg="6"
      md="6"
      sm="12"
      xs="12"
      cols="12"
    >
      <InvestigationCard
        :redirect-path="'/investigation/person/createCase/PhysicalPersonCase'"
        asset-image="InvPerson.jpg"
        title="$l.paths.personInvestigation"
      />
    </v-col>

    <v-col
      v-if="curPathName === 'InvestigationPerson'"
      xl="6"
      lg="6"
      md="6"
      sm="12"
      xs="12"
      cols="12"
    >
      <InvestigationCard
        :redirect-path="'/investigation/person/createCase/LegalEntityCase'"
        asset-image="InvCompany.jpg"
        title="$l.paths.legalEntityInvestigation"
      />
    </v-col>
  </v-row>
</template>

<script>
import InvestigationCard from './InvestigationCard.vue'
export default {
  name: 'AddInvestigation',
  components: {
    InvestigationCard
  },
  computed: {
    curPathName () {
      return this.$route.name
    }
  }
}
</script>
