<template>
  <div>
    <app-api-call
      :call-result="callResult"
    />

    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :mobile-breakpoint="0"
      :loading="loading"
      :custom-sort="customSort"
      class="ma-0 pa-0 okaml_cursor_pointer"
      @click:row="redirectItem"
    >
      <template v-slot:[`item.created`]="{ item }">
        <span style="white-space: nowrap">{{ $utils.formatDate(item.created) }}</span>
      </template>
      <template v-slot:top>
        <v-toolbar
          flat
          color="white"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$i18n.t('$l.app.search')"
            single-line
            hide-details
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="error"
          text
          small
          @click.stop="deleteCase(item)"
        >
          {{ $i18n.t('$l.app.delete') }}
        </v-btn>
      </template>
    </v-data-table>

    <basic-dialog
      v-if="caseToDelete !== null"
      v-model="showConfirmDeleteCase"
      title="$l.case.confirmCaseDeletion"
      save-button
      save-button-label="$l.app.confirm"
      :loading-save-button="loadingSaveButton"
      @close-dialog="showConfirmDeleteCase = false"
      @save-dialog="doDeleteCase()"
    >
      <v-container>
        <span
          class="font-weight-medium"
        >
          <div>
            {{ caseToDelete.caseId }}
          </div>
          <div>
            {{ caseToDelete.name }}
          </div>
          <div>
            {{ $utils.formatDate(caseToDelete.created) }}
          </div>
        </span>
      </v-container>
    </basic-dialog>
  </div>
</template>

<script>
export default {
  name: 'ContinueInvestigationTable',
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      items: [],
      search: '',
      loading: false,
      showConfirmDeleteCase: false,
      caseToDelete: null,
      loadingSaveButton: false
    }
  },
  computed: {
    headers () {
      return [
        { text: this.$t('$l.case.name'), value: 'name' },
        { text: this.$t('$l.case.status'), value: 'loc_status', align: ' d-none' },
        { text: this.$t('$l.case.type'), value: 'loc_type' },
        { text: this.$t('$l.case.created'), value: 'created', align: 'right', sortable: true },
        { text: this.$t('$l.case.actions'), value: 'actions', align: 'end' }
      ]
    },
    curPathName () {
      return this.$route.name
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.loading = true
      this.callResult.finished = false
      this.callResult.error = null
      let caseType = 'transaction'
      if (this.curPathName === 'InvestigationPerson') {
        caseType = 'person'
      }
      this.$xapi.get(`case/${caseType}/caseStatus/OPN`)
        .then(r => {
          this.items = r.data
          this.customFilter()
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.loading = false
          this.callResult.finished = true
        })
    },
    customSort (items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === 'created') {
          if (!isDesc[0]) {
            return new Date(b[index]) - new Date(a[index])
          } else {
            return new Date(a[index]) - new Date(b[index])
          }
        } else {
          if (typeof a[index] !== 'undefined') {
            if (!isDesc[0]) {
              return a[index].toLowerCase().localeCompare(b[index].toLowerCase())
            } else {
              return b[index].toLowerCase().localeCompare(a[index].toLowerCase())
            }
          }
        }
      })
      return items
    },
    redirectItem (item) {
      if (item && item.caseId && item.type) {
        if (item.type === 'TransactionCase') {
          this.$router.push({ name: 'ProcessCase', params: { caseId: item.caseId } })
        } else {
          this.$router.push({ name: 'ProcessCasePerson', params: { caseId: item.caseId } })
        }
      }
    },
    customFilter () {
      Array.from(this.items, c => {
        c.loc_type = this.$i18n.t(`$l.case.${c.type}`)
      })
      Array.from(this.items, c => {
        c.loc_status = c.status === '' ? this.$i18n.t('$l.case.new') : this.$i18n.t(`$l.case.${c.status}`)
      })
    },
    deleteCase (item) {
      this.caseToDelete = item
      this.showConfirmDeleteCase = true
    },
    doDeleteCase () {
      if (this.caseToDelete === null) return
      this.loadingSaveButton = true
      this.$xapi.delete(`case/delete/${this.caseToDelete.caseId}`)
        .then(r => {
          this.init()
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.showConfirmDeleteCase = false
          this.loadingSaveButton = false
        })
    }
  }
}
</script>
