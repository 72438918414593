<template>
  <v-container
    fluid
  >
    <basic-card
      title="$l.investigation.newInvestigation"
      class="mt-9"
    >
      <AddInvestigation />
    </basic-card>

    <basic-card
      title="$l.investigation.inProgress"
      class="mt-12"
    >
      <ContinueInvestigationTable />
    </basic-card>
  </v-container>
</template>

<script>
import AddInvestigation from './AddInvestigation.vue'
import ContinueInvestigationTable from './ContinueInvestigationTable.vue'

export default {
  name: 'InvestigationWrapper',
  components: {
    AddInvestigation,
    ContinueInvestigationTable
  }
}
</script>
