<template>
  <InvestigationWrapper />
</template>

<script>
import InvestigationWrapper from '@/components/investigation/InvestigationWrapper.vue'

export default {
  name: 'Investigation',
  components: {
    InvestigationWrapper
  }
}
</script>
